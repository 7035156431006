<!-- Entête de la page -->
<lib-page-header [title]="'Comités'">
  <div [libMyRemoveIfFeatureOff]="'auto-affectation'" centered-elements>
    <lib-pxl-toggle
      [labels]="toggleLabels"
      [defaultLabel]="comiteToggleState ? ToggleValues.MES_COMITES : ToggleValues.TOUS_COMITES"
      (changeToggleEvent)="onChangeToggle($event)"
    ></lib-pxl-toggle>
  </div>
  <div right-elements></div>
</lib-page-header>

<div class="page-content--no-scroll filter_table_content">
  <div class="filter-container" *ngIf="!comiteToggleState">
    <pxl-comite-search
      *ngIf="canShowFilter"
      [aapFilterList]="aapFilterList"
      [dateReleveFilterList]="dateReleveFilterList"
      [comiteCreatorFilterList]="comiteCreatorFilterList"
      (searchEventEmitter)="searchEventCalled($event)"
    ></pxl-comite-search>
  </div>

  <div class="table-container">
    <pxl-comites-tableau [dataSource]="dataSource" [(selection)]="selection" (favoriteChange)="favoriteChange($event)">
    </pxl-comites-tableau>
  </div>
</div>

import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ComiteService } from '@services-evaluateur/comite.service';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import {
  Aap,
  Comite,
  FilterDataInterface,
  FilterDateReleve,
  PreferenceUtilisateur,
  PreferencesUtilisateurService,
  SearchComitesFilter,
  ShowToastrService,
  SubscriptionDestroyerComponent,
  enumValues,
} from '@shared-ui';
import { AapService } from '@services-evaluateur/aap.service';
import { catchError, forkJoin, of } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'pxl-comites-list',
  templateUrl: './comites.list.component.html',
  styleUrls: ['./comites.list.component.scss'],
})
export class ComitesListComponent extends SubscriptionDestroyerComponent implements OnInit {
  COMITES_FAVORIS_IDS_PREFERENCES_FIELD_NAME = 'comitesFavorisIds';
  comites: Comite[];
  aaps: Aap[];
  mesComites: Comite[];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  selection = new SelectionModel<Comite>(true, []);
  toggleLabels: string[];
  comiteToggleState = false;
  canShowFilter = false;

  aapFilterList: FilterDataInterface[] = [];
  dateReleveFilterList: FilterDataInterface[] = [];
  comiteCreatorFilterList: FilterDataInterface[] = [];

  constructor(
    public comiteService: ComiteService,
    public aapService: AapService,
    private showToastrService: ShowToastrService,
    public matDialog: MatDialog,
    public userService: UtilisateurService,
    public preferencesUtilisateurService: PreferencesUtilisateurService,
    private datePipe: DatePipe
  ) {
    super();
    this.toggleLabels = enumValues(ToggleValues);
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadComites(filter?: SearchComitesFilter) {
    this.selection?.clear();
    this.comiteService
      .getComites(filter)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (response: HttpResponse<Comite[]>) => {
          this.comites = response.body ?? [];
          if (!filter) {
            this.updateMesComitesArray();
            this.comiteToggleState = this.mesComites?.length > 0;
          }
          this.updateComiteDataSource();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  loadData() {
    this.selection?.clear();
    forkJoin({
      aaps: this.aapService.getAaps(),
      comites: this.comiteService.getComites(),
    })
      .pipe(catchError(error => of(error)))
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: result => {
          this.handleComitesResponse(result.comites);
          this.handleAapsResponse(result.aaps);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
        complete: () => {
          this.canShowFilter = true;
        },
      });
  }

  handleAapsResponse(response: HttpResponse<Aap[]>) {
    if (response) {
      this.aaps = response.body;
      this.updateAapFilterList();
      this.updateDateReleveFilterList();
    }
  }

  handleComitesResponse(response: HttpResponse<Comite[]>) {
    if (response) {
      this.comites = response.body;
      this.updateComiteCreatorFilterList();
      this.updateMesComitesArray();
    }
    this.selectRightToggle();
  }

  updateMesComitesArray(): void {
    this.preferencesUtilisateurService
      .getPreferenceForCurrentUser()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (response: PreferenceUtilisateur) => {
          if (response) {
            this.updateComitesFavoriteFlag(response);
            this.mesComites = this.comites.filter(comite => comite.favorite);
            this.selectRightToggle();
          }
        },
        error: () => {
          this.mesComites = [];
        },
      });
  }

  selectRightToggle(forceRedirection: boolean = true): void {
    if (forceRedirection) {
      this.comiteToggleState = this.mesComites?.length > 0;
    } else if (this.mesComites?.length === 0) {
      this.comiteToggleState = false;
    }
    this.updateComiteDataSource();
  }

  updateComitesFavoriteFlag(preferenceUtilisateur: PreferenceUtilisateur): void {
    const comitesFavorisIds = preferenceUtilisateur?.preferences?.[this.COMITES_FAVORIS_IDS_PREFERENCES_FIELD_NAME];
    this.comites.forEach(comite => (comite.favorite = comitesFavorisIds.includes(comite.id)));
  }

  searchEventCalled(event: SearchComitesFilter): void {
    this.loadComites(event);
  }

  favoriteChange(event: Comite): void {
    event.favorite = !event.favorite;
    this.addOrRemoveComiteFromFavorites(event);
  }

  addOrRemoveComiteFromFavorites(comite: Comite): void {
    this.comites.find(c => c.id === comite.id).favorite = comite.favorite;
    const favoriteComitesIds = this.comites.filter(comite => comite.favorite).map(comite => comite.id);

    this.preferencesUtilisateurService
      .updatePreference(this.COMITES_FAVORIS_IDS_PREFERENCES_FIELD_NAME, favoriteComitesIds)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (response: PreferenceUtilisateur) => {
          if (response) {
            this.updateComitesFavoriteFlag(response);
            this.mesComites = this.comites.filter(comite => comite.favorite);
            this.selectRightToggle(false);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  updateComiteCreatorFilterList() {
    if (!this.comites) {
      this.comiteCreatorFilterList = [];
      return;
    }

    const _fullComitesList = this.comites
      .map(comite => comite.auteur)
      .filter(auteur => !!auteur)
      .map(auteur => {
        return <FilterDataInterface>{
          id: auteur?.matricule,
          value: `${auteur?.prenom} ${auteur?.nom}`,
          isSelected: false,
        };
      });
    this.comiteCreatorFilterList = [...new Map(_fullComitesList.map(item => [item['id'], item])).values()];
  }

  updateAapFilterList(): void {
    if (!this.aaps) {
      this.aapFilterList = [];
      return;
    }

    this.aapFilterList = this.aaps.map(aap => {
      return {
        id: aap.id,
        value: aap.code,
        isSelected: false,
      };
    });
  }

  updateDateReleveFilterList(): void {
    if (!this.aaps) {
      this.dateReleveFilterList = [];
      return;
    }

    const _dateReleveFilterList = this.aaps
      .flatMap(aap => {
        if (!aap || !aap.dateAutres) {
          return [];
        }

        return aap.dateAutres
          .filter(dateAutre => dateAutre && 'RELEVE' === dateAutre.type)
          .map(dateAutre => {
            return <FilterDataInterface>{
              id: {
                dateReleve: dateAutre.valeur,
                idAap: aap.id,
              },
              value: `${this.datePipe.transform(dateAutre.valeur, 'dd/MM/yyyy')} (${aap.code})`,
              isSelected: false,
            };
          });
      })
      .reduce((accumulator: any, filterDataInterface: FilterDataInterface) => {
        if (!accumulator[filterDataInterface.value]) {
          accumulator[filterDataInterface.value] = {
            id: { idAap: (<FilterDateReleve>filterDataInterface.id).idAap, datesReleves: [] },
            value: filterDataInterface.value,
            isSelected: false,
          };
        }

        accumulator[filterDataInterface.value].id.datesReleves.push((<FilterDateReleve>filterDataInterface.id).dateReleve);

        return accumulator;
      }, {});
    this.dateReleveFilterList = Object.values(_dateReleveFilterList ?? {});
  }

  private updateComiteDataSource() {
    if (this.comiteToggleState) {
      this.dataSource = new MatTableDataSource<Comite>(this.mesComites);
    } else {
      this.dataSource = new MatTableDataSource<Comite>(this.comites);
    }
  }

  onChangeToggle($event: string): void {
    this.comiteToggleState = $event === ToggleValues.MES_COMITES;
    this.updateComiteDataSource();
    this.selection?.clear();
  }

  protected readonly ToggleValues = ToggleValues;
}

enum ToggleValues {
  TOUS_COMITES = 'Tous les comités',
  MES_COMITES = 'Mes comités',
}

import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ComiteProjetsListFilterComponent } from '@features-evaluateur/comites/comites-list/comite-projets-list/comite-projets-list-filter/comite-projets-list-filter.component';
import { AapService } from '@services-evaluateur/aap.service';
import { ComiteService } from '@services-evaluateur/comite.service';
import { ProjetService } from '@services-evaluateur/projet.service';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import { ZoneService } from '@services-evaluateur/zone.service';
import { ComiteDecisionModalComponent } from '@shared-evaluateur/components/modals/comite-decision-modal/comite-decision-modal.component';
import { DateSelectModalComponent } from '@shared-evaluateur/components/modals/date-select-modal/date-select-modal.component';
import { ProjetUtils } from '@shared-evaluateur/utils/projet-utils';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import {
  Aap,
  CentreMesure,
  Comite,
  ComiteProjet,
  ConfirmModalComponent,
  DateTimeUtils,
  EnumDecisionComite,
  EnumPhaseComite,
  EnumStatutComite,
  FilterDataInterface,
  FilterDateReleve,
  ProjetTableElement,
  Region,
  SearchObjectComites,
  ShowToastrService,
  SubscriptionDestroyerComponent,
  Utilisateur,
  getEtapeDataList,
  getStatutDataList,
  ExportCsvFunction,
  SearchObject,
  ProjetLight,
} from '@shared-ui';
import { Observable, catchError, forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-comite-projets-list',
  templateUrl: './comite-projets-list.component.html',
  styleUrls: ['./comite-projets-list.component.scss'],
})
export class ComiteProjetsListComponent extends SubscriptionDestroyerComponent implements OnInit {
  @ViewChild('filterComponent') filterComponent: ComiteProjetsListFilterComponent;

  displayedColumns: string[] = [
    'acronyme',
    'structureCf',
    'libelleThematique',
    'budget',
    'propositionComite',
    'decisionComite',
    'dateDecisionEtat',
  ];

  selectedProjetsEvaluation = new SelectionModel<ProjetTableElement>(true, []);
  selectedProjetsAudition = new SelectionModel<ProjetTableElement>(true, []);
  selectedProjetsSelection = new SelectionModel<ProjetTableElement>(true, []);
  selectedProjetsIds: string[] = [];

  preselectionProjetTableData: ProjetTableElement[] = [];
  auditionProjetTableData: ProjetTableElement[] = [];
  selectionProjetTableData: ProjetTableElement[] = [];

  comiteHasPreselectionProjet = false;
  comiteHasAuditionProjet = false;
  comiteHasSelectionProjet = false;

  centreMesureList: CentreMesure[];
  regionList: Region[];
  assignedProjetList: ProjetLight[];
  comite: Comite;
  comiteId: string;
  user: Utilisateur;
  canUserEditComite: boolean;
  filterIsReady = false;

  thematiqueDataList: FilterDataInterface[] = [];
  etapeDataList: FilterDataInterface[] = [];
  statutDataList: FilterDataInterface[] = [];
  centreMesureDataList: FilterDataInterface[] = [];
  regionDataList: FilterDataInterface[] = [];
  dateReleveDataList: FilterDataInterface[] = [];
  comiteAaps: string[] = [];

  constructor(
    public projetService: ProjetService,
    public comiteService: ComiteService,
    public drDlHttpService: ZoneService,
    public matDialog: MatDialog,
    public exportCsvFunction: ExportCsvFunction,
    public router: Router,
    private route: ActivatedRoute,
    public showToastrService: ShowToastrService,
    private sharedFunction: SharedFunction,
    public utilisateurService: UtilisateurService,
    public datePipe: DatePipe,
    public aapService: AapService
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedProjetsEvaluation.changed.subscribe(() => this.setSelectedProjets());
    this.selectedProjetsAudition.changed.subscribe(() => this.setSelectedProjets());
    this.selectedProjetsSelection.changed.subscribe(() => this.setSelectedProjets());

    this.comiteId = this.route.snapshot.params.id;

    const fork = forkJoin({
      comiteResponse: this.comiteService.getComiteById(this.comiteId),
      centreMesureResponse: this.drDlHttpService.getDirectionRegionaleCentreMesure(),
      regionResponse: this.drDlHttpService.getRegions(),
      assignedProjetResponse: this.projetService.searchLightProjetsForInstructeurExterne({ affectedOnly: true } as SearchObject),
    });

    fork.pipe(catchError(error => of(error)));

    fork.pipe(this.takeUntilDestroyed()).subscribe({
      next: result => {
        this.centreMesureList = result.centreMesureResponse.body;
        this.regionList = result.regionResponse.body;
        this.assignedProjetList = result.assignedProjetResponse;
        this.handleComiteLoaded(result.comiteResponse.body);
        this.loadSearchData();
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  setSelectedProjets(): void {
    this.selectedProjetsIds = [
      ...this.selectedProjetsEvaluation.selected,
      ...this.selectedProjetsAudition.selected,
      ...this.selectedProjetsSelection.selected,
    ].map(projet => projet.id);
  }

  searchEventCalled(searchObject: SearchObjectComites): void {
    this.projetService
      .getProjetsComite(searchObject)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (projets: ComiteProjet[]) => {
          this.setProjetTableData(projets);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  setProjetTableData(projets: ComiteProjet[]): void {
    this.preselectionProjetTableData = ProjetUtils.convertProjetComiteToTableElement(
      projets?.filter(projet => projet.phase === EnumPhaseComite.EVALUATION)
    );
    this.auditionProjetTableData = ProjetUtils.convertProjetComiteToTableElement(
      projets?.filter(projet => projet.phase === EnumPhaseComite.AUDITION)
    );
    this.selectionProjetTableData = ProjetUtils.convertProjetComiteToTableElement(
      projets?.filter(projet => projet.phase === EnumPhaseComite.SELECTION)
    );
  }

  loadSearchData(): void {
    this.updateThematiqueDataList();
    this.updateDateReleveDataList();
    this.updateEtapeDataList();
    this.updateStatutDataList();
    this.updateCentreMesureDataList();
    this.updateRegionDataList();

    this.filterIsReady = true;
  }

  updateThematiqueDataList() {
    this.thematiqueDataList =
      this.comite.projetComites
        ?.filter(projet => projet?.codeThematique || projet?.libelleThematique)
        .map(projet => {
          return {
            id: projet.codeThematique,
            value: projet.libelleThematique ?? projet.codeThematique,
            isSelected: false,
          } as FilterDataInterface;
        })
        .filter((currentFilter, index, filters) => index === filters.findIndex(filter => filter.value === currentFilter.value)) ?? [];
  }

  updateDateReleveDataList() {
    const _projetComites = this.comite?.projetComites ?? [];
    const _dateReleveDataList = _projetComites
      .flatMap(projetComite => {
        const datesReleves = [];
        const dateDepotReleve = this.transformDateReleve(projetComite.releveDepot, projetComite.aapId, projetComite.aapCode);
        if (dateDepotReleve) {
          datesReleves.push(dateDepotReleve);
        }
        const datePreDepotReleve = this.transformDateReleve(projetComite.relevePreDepot, projetComite.aapId, projetComite.aapCode);
        if (datePreDepotReleve) {
          datesReleves.push(datePreDepotReleve);
        }

        return datesReleves;
      })
      .reduce((accumulator: any, filterDataInterface: FilterDataInterface) => {
        if (!accumulator[filterDataInterface.value]) {
          accumulator[filterDataInterface.value] = {
            id: { idAap: (<FilterDateReleve>filterDataInterface.id).idAap, datesReleves: [] },
            value: filterDataInterface.value,
            isSelected: false,
          };
        }

        accumulator[filterDataInterface.value].id.datesReleves.push((<FilterDateReleve>filterDataInterface.id).dateReleve);
        return accumulator;
      }, {});
    this.dateReleveDataList = Object.values(_dateReleveDataList ?? {});
  }

  transformDateReleve(dateReleve: Date, aapId: string, aapCode: string): FilterDataInterface | null {
    if (!dateReleve) {
      return null;
    }

    const _dateDepot = new Date(dateReleve);
    if (!_dateDepot) {
      return null;
    }

    return {
      id: {
        dateReleve: _dateDepot,
        idAap: aapId,
      },
      value: `${this.datePipe.transform(_dateDepot, 'dd/MM/yyyy')} (${aapCode})`,
      isSelected: false,
    };
  }

  updateEtapeDataList(): void {
    this.etapeDataList = getEtapeDataList();
  }

  updateStatutDataList(): void {
    this.statutDataList = getStatutDataList();
  }

  updateCentreMesureDataList(): void {
    this.centreMesureDataList = this.centreMesureList
      .map(centreMesure => {
        return {
          id: centreMesure.codeCentreMesure,
          value: centreMesure.libCentreMesure,
          isSelected: false,
        };
      })
      .sort((a, b) => (a.value > b.value ? 1 : -1));
  }

  updateRegionDataList(): void {
    this.regionDataList = this.regionList.map(region => {
      return {
        id: region.nomRegion,
        value: region.nomRegion,
        isSelected: false,
      };
    });
  }

  handleComiteLoaded(comite: Comite): void {
    this.comite = comite;
    this.comiteAaps = [...new Set(comite?.projetComites?.map(projet => projet.aapId) || [])];
    this.comite.id = this.comiteId;
    this.comiteHasPreselectionProjet = this.comite.projetComites?.some(projet => projet.phase === EnumPhaseComite.EVALUATION);
    this.comiteHasAuditionProjet = this.comite.projetComites?.some(projet => projet.phase === EnumPhaseComite.AUDITION);
    this.comiteHasSelectionProjet = this.comite.projetComites?.some(projet => projet.phase === EnumPhaseComite.SELECTION);
    this.loadUser();
    this.setProjetTableData(this.comite.projetComites);
  }

  loadUser(): void {
    this.utilisateurService
      .getUtilisateurObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: response => {
          this.user = response;
          this.canUserEditComite = this.sharedFunction.canUserEditComite(this.user, this.comite);
        },
      });
  }

  closeComite(): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: 'Clôture du comité',
        description: `<p>En confirmant l'action, ce comité sera clôturé. Cette action est irreversible.<br>
        Confirmez-vous l’action ?</p>`,
        textGoButton: 'Confirmer',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.comiteService
          .closeComites([this.comiteId])
          .pipe(this.takeUntilDestroyed())
          .subscribe({
            next: response => {
              this.comite.statut = response.body[0].statut;
              this.showToastrService.success('Comité cloturé');
            },
          });
      }
    });
  }

  editComite(): void {
    this.router.navigate(['../modification', this.comiteId], { relativeTo: this.route });
  }

  isComiteClosed(): boolean {
    return this.comite.statut === EnumStatutComite.CLOTURE;
  }

  isComiteBrouillon(): boolean {
    return this.comite.statut === EnumStatutComite.BROUILLON;
  }

  exportPvEvaluation(): void {
    if (this.comiteHasPreselectionProjet) {
      this.exportPv('PV_Présélection', ComiteService.prototype.downloadExportEvaluation);
    } else {
      this.showToastrService.error("Aucun projet n'est sélectionné pour une décision en présélection sur ce comité.");
    }
  }

  exportPvAudition(): void {
    if (this.comiteHasAuditionProjet) {
      this.exportPv('PV_Audition', ComiteService.prototype.downloadExportAudition);
    } else {
      this.showToastrService.error("Aucun projet n'est sélectionné pour une décision en audition sur ce comité.");
    }
  }

  exportPvSelection(): void {
    if (this.comiteHasSelectionProjet) {
      this.exportPv('PV_Sélection', ComiteService.prototype.downloadExportSelection);
    } else {
      this.showToastrService.error("Aucun projet n'est sélectionné pour une décision en sélection sur ce comité.");
    }
  }

  exportPv(filenamePrefix: string, func: (id: string) => Observable<HttpResponse<string>>): void {
    if (!this.comite.id) {
      return;
    }
    // Download CSV
    func.call(this.comiteService, this.comite.id).subscribe({
      next: response => {
        if (response?.body) {
          const fileName = this.comite.nom + '_' + filenamePrefix + '_' + this.datePipe.transform(new Date(), 'dd_MM_yyyy');
          this.exportCsvFunction.downloadFile(response.body, fileName);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  @Input() commentaire = '';

  addDateEnvoi(): void {
    if (!this.selectedProjetsIds?.length) {
      this.showToastrService.error('Vous devez sélectionner au moins un projet.');
      return;
    }

    const dialogRef = this.matDialog.open(DateSelectModalComponent, {
      data: {
        title: `Ajouter la date d'envoi pour validation par l’Etat`,
        subtitle: `La date choisie s'appliquera à l'ensemble des projets sélectionnés`,
        label: `Date d'envoi pour validation par l'Etat`,
        placeholder: `Date d'envoi pour validation par l'Etat`,
        textGoButton: 'Confirmer',
        textReturnButton: 'Annuler',
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.date) {
        const dateRequest = {
          ids: this.selectedProjetsIds,
          dateEnvoiValidationEtat: DateTimeUtils.toUtcDate(result.date),
          comiteId: this.comiteId,
        };
        this.projetService
          .putDateEnvoi(dateRequest)
          .pipe(this.takeUntilDestroyed())
          .subscribe({
            next: () => {
              this.showToastrService.success("La date d'envoi a été ajoutée avec succès.");
            },
            error: err => {
              this.showToastrService.checkCodeError(err?.error);
            },
          });
      }
    });
  }

  addDecision(): void {
    if (!this.selectedProjetsIds?.length) {
      this.showToastrService.error('    Vous devez sélectionner au moins un projet.');
      return;
    }

    const dialogRef = this.matDialog.open(ComiteDecisionModalComponent, {
      data: {
        title: `Ajouter la date d'envoi pour validation par l’Etat`,
        subtitle: `La date choisie s'appliquera à l'ensemble des projets sélectionnés`,
        label: `Date d'envoi pour validation par l'Etat`,
        placeholder: `Date d'envoi pour validation par l'Etat`,
        textGoButton: 'Confirmer',
        textReturnButton: 'Annuler',
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data) {
        const comiteRequest = {
          // ids: it is projet ids
          projetsIds: this.selectedProjetsIds,
          dateDecisionEtat: DateTimeUtils.toUtcDate(result.data.dateDecisionEtat),
          avis: result.data.validationPropositionComite,
          commentaire: result.data.avisEtat,
        };
        this.projetService
          .putDecision(comiteRequest, this.comiteId)
          .pipe(this.takeUntilDestroyed())
          .subscribe({
            next: () => {
              this.refreshProjetDataSource(comiteRequest.projetsIds, comiteRequest.dateDecisionEtat, comiteRequest.avis);
              this.showToastrService.success('La décision ainsi que la date de validation ont été ajoutées avec succès.');
            },
            error: err => {
              this.showToastrService.checkCodeError(err?.error);
            },
          });
      }
    });
  }

  private refreshProjetDataSource(updatedProjetIds: string[], dateDecisionEtat: Date, decisionComite: EnumDecisionComite): void {
    this.comite.projetComites?.forEach(oldProjet => {
      if (updatedProjetIds.includes(oldProjet.id)) {
        oldProjet.dateDecisionEtat = dateDecisionEtat;
        oldProjet.decisionComite = decisionComite;
      }
    });
    this.setProjetTableData(this.comite.projetComites);
  }

  /**
   * Redirige vers la page projet ayant les informations du projet
   */
  goToComiteProjet(projet: ComiteProjet, phase: EnumPhaseComite): void {
    if (!this.isAssignedProjet(projet)) {
      this.showToastrService.error("Vous n'êtes pas affecté sur ce projet, vous ne pouvez pas consulter son contenu.");
      return;
    }

    this.aapService
      .getAapById(projet.aapId)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (response: HttpResponse<Aap>) => this.redirectToNotation(projet.id, phase, response.body.ilab),
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  isAssignedProjet(projet: ComiteProjet): boolean {
    return this.assignedProjetList.some(assignedProjet => assignedProjet.id === projet.id);
  }

  redirectToNotation(projetId: string, phase: EnumPhaseComite, ilab: boolean) {
    const ilabPath = ilab ? '-ilab' : '';
    const phaseToString = EnumPhaseComite.toString(phase).toLowerCase();
    const url = ['projets', projetId];

    if (phase === EnumPhaseComite.SELECTION) {
      url.push('projet-instruction', 'selection');
    } else {
      url.push(`projet-notation-${phaseToString}${ilabPath}`);
    }

    if (url.length > 0) {
      window.open(this.router.serializeUrl(this.router.createUrlTree(url)), '_blank');
    }
  }

  protected readonly EnumPhaseComite = EnumPhaseComite;
}

import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Comite, EnumStatutComite } from '@shared-ui';

export interface FavoriteAction {
  comite: Comite;
  favorite: boolean;
}

@Component({
  selector: 'pxl-comites-tableau',
  templateUrl: './comites-tableau.component.html',
  styleUrls: ['./comites-tableau.component.scss'],
})
export class ComitesTableauComponent implements OnChanges {
  @Input() dataSource: MatTableDataSource<any>;
  @Input() selection = new SelectionModel<Comite>(true, []);
  @Output() selectionChange = new EventEmitter<SelectionModel<Comite>>();
  @Output() favoriteChange = new EventEmitter<Comite>();

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['select', 'nom', 'createurName', 'dateTenue', 'dateCloture', 'actions'];

  constructor(public route: ActivatedRoute, public router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource'] && this.sort && this.dataSource) {
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'nom':
            return item?.nom;
          case 'dateTenue':
            return item?.dateTenue;
          case 'dateCloture':
            return item?.dateCloture;
          case 'createurName':
            return item?.auteur?.nom;
        }
      };
      this.dataSource.sort = this.sort;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */

  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  isStatutBrouillon(statut: EnumStatutComite) {
    return statut === EnumStatutComite.BROUILLON;
  }

  changeFavorite(comite: Comite): void {
    this.favoriteChange.emit(comite);
  }

  onGoToComite(id: string): void {
    this.router.navigate([id], { relativeTo: this.route });
  }
}

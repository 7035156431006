<table mat-table [dataSource]="dataSource" matSort matSortActive="nom" matSortDirection="asc" matSortDisableClear>
  <caption>
    Liste des comités
  </caption>

  <ng-container matColumnDef="select" class="">
    <th id="comite-select" mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? masterToggle() : null"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
      >
      </mat-checkbox>
    </th>
    <td id="id" mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
      <mat-checkbox (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)"> </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="nom" class="">
    <th id="comite-nom" mat-header-cell *matHeaderCellDef mat-sort-header>Nom du comité</th>
    <td id="nom" mat-cell *matCellDef="let element">
      <span>{{ element.nom }}</span>
      <span class="brouillonStatut" *ngIf="isStatutBrouillon(element.statut)"> Brouillon</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="createurName" class="">
    <th id="comite-createurName" mat-header-cell *matHeaderCellDef mat-sort-header>Créateur du comité</th>
    <td id="createurName" mat-cell *matCellDef="let element">
      <span>{{ element.auteur?.prenom }} {{ element.auteur?.nom }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="dateTenue" class="">
    <th id="comite-dateTenue" mat-header-cell *matHeaderCellDef mat-sort-header>Date et heure de tenue</th>
    <td id="dateTenue" mat-cell *matCellDef="let element">
      <span>{{ element.dateTenue | date : 'dd/MM/yyyy à HH:mm' }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="dateCloture" class="">
    <th id="comite-dateCloture" mat-header-cell *matHeaderCellDef mat-sort-header>Date de clôture</th>
    <td id="dateCloture" mat-cell *matCellDef="let element">
      <span>{{ element.dateCloture | date : 'dd/MM/yyyy' }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th id="aap-actions" mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button
        id="bookmark-comite"
        class="table__button"
        [matTooltip]="element.favorite ? 'Supprimer de mes comités' : 'Ajouter dans mes comités'"
        (click)="changeFavorite(element); $event.stopPropagation()"
      >
        <lib-pxl-icon *ngIf="!element.favorite" class="icon" icon="icon-bookmark-unfilled"></lib-pxl-icon>
        <lib-pxl-icon *ngIf="element.favorite" class="icon" icon="icon-bookmark-filled"></lib-pxl-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row class="table-row" *matRowDef="let row; columns: displayedColumns" style="cursor: pointer" (click)="onGoToComite(row.id)"></tr>
</table>

<div class="no-result" *ngIf="dataSource?.data?.length === 0">
  <img src="assets/images/no_projects.svg" alt="Pas de résultats" />
  <span id="large-span-result">Il n'y a rien ici...</span>
  <span id="small-span-result">0 comité répertorié.</span>
</div>
